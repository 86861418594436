import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import request from '../utils/Helper/Request';
import { yupResolver } from '@hookform/resolvers/yup';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';

const title = "Subscribe";
// const subtitle = "Register With Social Media";

const SubscribePage = () => {
  const [isLoading, setIsLoading] = useState(false);

  //** Default Values */
  const defaultValues = {
    phone: '',
    type: ''
  };

  // ** Question Schema
  const subscribeSchema = yup.object().shape({
    phone: yup.string().required('Phone is a required field'),
    type: yup.string().required('Type is a required field')
  });

  //** UseForm */
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({ defaultValues, resolver: yupResolver(subscribeSchema) });

  //** Handle Onsubmit */
  const onSubmit = values => {
    setIsLoading(true);
    request(`${process.env.REACT_APP_API_URL}/service/trivial/subscribe`, { 
      method: 'POST', 
      body: values
    })
    .then((response) => {
      setIsLoading(false);
      if(response.data.status === true) {
        reset();
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch((error) => {
      if (error.response.status > 200) {
        setIsLoading(false);
        toast.error(error.response.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  };
  return (
    <div>
      <Header />
      <PageHeader title={title}/>
      <div className="login-section padding-tb">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="account-form">
              <div className="form-group">
                <label>PHONE NUMBER</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Phone Number *"
                  {...register("phone", { required: true })}
                />
                {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
              </div>
              <div className="form-group">
                <label>SUBSCRIPTION TYPE</label>
                <select 
                  name="type" 
                  className="w-100"
                  {...register("type", { required: true })}
                >
                  <option value="">---Select---</option>
                  <option value="day">Daily</option>
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                </select>
                {errors.type && <span className="text-danger">{errors.type.message}</span>}
              </div>
              <div className="form-group">
                <button disabled={isSubmitting || isLoading} className="d-block lab-btn">
                  <span>Subscribe Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
}

export default SubscribePage;