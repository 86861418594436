import { useContext } from 'react';
import {Routes, Route } from "react-router-dom";
import HomePages from '../pages/home';
import ProfilePage from '../pages/profile';
import ForeZeroPage from '../pages/forezero';
import ContactPage from '../pages/contact';
import AboutPage from '../pages/about';
import LoginPage from '../pages/login';
import SubscribePage from '../pages/subscribe';
import GetStartedPage from '../pages/getStarted';
import QuestionPage from '../pages/question';
import AnswerPage from '../pages/answer';
import GenerateNominateCodePage from '../pages/generateCode';
import NominatePage from '../pages/nominate';
import VotePage from '../pages/vote';
import ProductsPage from '../pages/products';
import GetScorePage from '../pages/getscore';
import ExportScorePage from '../pages/exportScore';
import UploadQuestionPage from '../pages/uploadquestion';
import GetNomineesPage from '../pages/getNominees';
import ExportNomineesPage from '../pages/exportNominees';
import UploadContestantsPage from '../pages/uploadcontestants';
import ExportVotingPage from '../pages/exportvoting';
import GetVotingPage from '../pages/getvoting';
import ExportaudienceparticipationPage from '../pages/exportaudienceparticipation';
import SuccessPage from '../pages/success';
import { AuthContext } from '../utils/Hooks/useAuth';

const Routers = () => {
  const { isLoggedIn } = useContext(AuthContext);
  
  return (
    <Routes>
      <Route path="/" element={<HomePages />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="*" element={<ForeZeroPage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="subscribe" element={<SubscribePage />} />
      <Route path="get-started" element={<GetStartedPage />} />
      <Route path="nominatecode" element={<GenerateNominateCodePage />} />
      <Route path="nominate" element={<NominatePage />} />
      <Route path="vote" element={<VotePage />} />
      <Route path="question" element={<QuestionPage />} />
      <Route path="answer" element={<AnswerPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="success" element={<SuccessPage />} />
      {isLoggedIn && (
      <>
      <Route path="upload-question" element={<UploadQuestionPage />} />
      <Route path="get-scores" element={<GetScorePage />} />
      <Route path="export-scores" element={<ExportScorePage />} />
      <Route path="products" element={<ProductsPage />} />
      <Route path="get-nominees" element={<GetNomineesPage />} />
      <Route path="export-nominees" element={<ExportNomineesPage />} />
      <Route path="upload-contestants" element={<UploadContestantsPage />} />
      <Route path="get-voting" element={<GetVotingPage />} />
      <Route path="export-voting" element={<ExportVotingPage />} />
      <Route path="export-audience-participation" element={<ExportaudienceparticipationPage />} />
      </>
      )}
    </Routes>
  );
};

export default Routers;
