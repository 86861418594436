import React, { Component } from 'react';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import SuccessImg from '../assets/images/success.svg'
const infotitle = "Successful Nomination";
const infodesc = "Follow the below intruction to complete your nomination";


let ContactInfoList = [
    {
        imgUrl: 'assets/images/contact/04.png',
        imgAlt: 'contact info thumb',
        infoTitle: 'Step 1:',
        infoDesc: (
          <>
            Send a 1-minute video with your exclusive code via WhatsApp to{' '}
            <a href="https://wa.link/4conub" target="_blank" rel="noopener noreferrer">
              +234 806 376 6036
            </a>
            .
          </>
        )
    },
    {
        imgUrl: 'assets/images/contact/04.png',
        imgAlt: 'contact info thumb',
        infoTitle: 'Step 2:',
        infoDesc: 'Share why you deserve a spot on the "40 and Fabulous" show!',
    },
    {
        imgUrl: 'assets/images/contact/04.png',
        imgAlt: 'contact info thumb',
        infoTitle: 'Step 3:',
        infoDesc: 'Fortune could be smiling upon you! Come join us on the path to fabulousness.',
    },
]

class ContactPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactNumber: '',
            contactMassage: '',
        };
    }

    render() {
        return (
            <div>
                <Header />
                <PageHeader title={'Successful'} />
                <div className="contact-section">
                    <div className="contact-top padding-tb aside-bg padding-b">
                        <div className="container">
                            <div className="account-wrapper">
                                <div className="contact-info-wrapper">
                                    <div className="contact-info-title text-center d-flex align-items-center flex-column justify-content-center">
                                        <img src={SuccessImg} />
                                        <h5 className="mt-2">{infotitle}</h5>
                                        <p className="mt-2">{infodesc}</p>
                                    </div>
                                    <div className="contact-info-content">
                                        {ContactInfoList.map((val, i) => (
                                            <div className="contact-info-item" key={i}>
                                                <div className="contact-info-inner">
                                                    <div className="contact-info-thumb">
                                                        <img 
                                                            src={`${val.imgUrl}`} 
                                                            alt={`${val.imgAlt}`}
                                                        />
                                                    </div>
                                                    <div className="contact-info-details">
                                                        <span className="fw-bold">{val.infoTitle}</span>
                                                        <p>{val.infoDesc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSection />
            </div>
        );
    }
}

export default ContactPage;