import React, { useState, useEffect } from 'react';
import request from '../utils/Helper/Request';
import Header from '../component/layout/header';
import Footer from '../component/layout/footer';
import PageHeader from '../component/layout/pageheader';
import SkeletonLoader from '../component/items/skeletonLoader';
import Table from 'react-bootstrap/Table';

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    /** Handle Fetch Data */
    const fetchData = async () => {
        setIsLoading(true);
        const response = await request(`${process.env.REACT_APP_API_URL}/partner/list/product`,{ method: 'GET' });
        setProducts(response.data);
        setIsLoading(false);
    };
  
    /** Handle Fetch useEffect */
    useEffect(() => {
        fetchData();
    }, []);
    
    return (
        <div>
            <Header />
            <PageHeader title={'Products'} />
            <section className="member-page-section">
                <div className="container">
                    <div className="member-wrapper">  
                        {/* <PaginaTion /> */}
                        {isLoading ? (
                            <SkeletonLoader loading={isLoading} />
                        ) : products.length === 0 ? (
                            <div className="text-center">
                                <h2>{'No product found!'}</h2>
                                <p> {'Subscribe to get more fascinating products'}</p>
                            </div>
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>SN</th>
                                        <th>Name</th>
                                        <th>Telco</th>
                                        <th>Amount</th>
                                        <th>Validity Days</th>
                                        <th>Aggregator Product Code</th>
                                        <th>Aggregator Credential Password</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((item, index) => 
                                        <tr key={index}>
                                            <td>1</td>
                                            <td>{item.name}</td>
                                            <td>{item.telco}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.validity_days}</td>
                                            <td>{item.aggregator_product_code}</td>
                                            <td>{item.aggregator_credential_password}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ProductsPage;