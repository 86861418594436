// import * as yup from 'yup';
// import React, { useState } from 'react';
// import Header from '../component/layout/header';
// import PageHeader from '../component/layout/pageheader';
// import Footer from '../component/layout/footer';
// import DatePicker from 'react-datepicker';
// import request from '../utils/Helper/Request';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { useForm, Controller } from 'react-hook-form';
// import SkeletonLoader from '../component/items/skeletonLoader';
// import Table from 'react-bootstrap/Table';
// import "react-datepicker/dist/react-datepicker.css";

// const GetScoresPage = () => {
//     const [data, setData] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);

//     // ** Default Values
//     const today = new Date();
//     const defaultValues = {
//         startDate: today
//     };
    
//     // ** Summary Schema
//     const summaryByDatesSchema = yup.object().shape({
//         startDate: yup.string().required('Product is a required field')
//     });
    
//     /** Onsubmit */
//     const {
//         control,
//         handleSubmit,
//         formState: { errors, isSubmitting },
//     } = useForm({ defaultValues, resolver: yupResolver(summaryByDatesSchema) });
    
//     const formDate = (date) => {
//         if (date) {
//             const dateObj = new Date(date);
//             const year = dateObj.getFullYear();
//             const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
//             const day = dateObj.getDate().toString().padStart(2, '0');
//             const formattedDate = `${year}-${month}-${day}`;
//             return formattedDate;
//         }
//     };

//     /** Handle Onsubmit */
//     const onSubmit = async (values) => {
//         // setIsLoading(true);
//         // const formattedDate = formDate(values.startDate);
//         // const response = await request(`${process.env.REACT_APP_API_URL}/partner/trivial/export-scores?date=${formattedDate}`,{ method: 'GET' });
//         // setData(response.data);
//         // setIsLoading(false);

//     // Navigate to a new URL in a new browser window
//     const newURL = 'https://www.example.com'; // Replace with your desired URL
//     window.open(newURL, '_blank');
//     };
    
//     return (
//         <div>
//             <Header />
//             <PageHeader title={'Get Scores'} />
//             <section className="member-page-section">
//                 <div className="container">

//                     <ul class="group-search">
//                         <li class="group-count">
//                             <p>All Groups</p>
//                             {/* <p>36</p> */}
//                         </li>
//                         <li class="group-search-btn">
//                             <form onSubmit={handleSubmit(onSubmit)}>
//                                 <div className="mb-3">
//                                     <Controller
//                                         name="startDate"
//                                         control={control}
//                                         defaultValue=""
//                                         render={({ field }) => (
//                                         <DatePicker className="form-control date"
//                                             selected={field.value}
//                                             onChange={field.onChange}
//                                             selectsStart
//                                             dateFormat="yyyy-MM-dd"
//                                         />
//                                         )}
//                                     />
//                                 </div>
//                                 {/* {errors.startDate && <span className="text-white">{errors.startDate.message}</span>}      */}
//                                 {/* <input type="text" placeholder="Search Group Name..."/> */}
//                                 <div class="input-button">
//                                     <input type="submit" value="Filter Now"/>
//                                 </div>
//                             </form>
//                         </li>
//                     </ul>

//                     {/* <div className="member-filter">
//                         <div className="member-filter-inner">
//                             <form onSubmit={handleSubmit(onSubmit)} className="filter-form">
//                                 <div className="d-flex align-items-center">
//                                     <div>
//                                         <div>{"Start Date"}</div>
//                                         <Controller
//                                             name="startDate"
//                                             control={control}
//                                             defaultValue=""
//                                             render={({ field }) => (
//                                             <DatePicker className="form-control date"
//                                                 selected={field.value}
//                                                 onChange={field.onChange}
//                                                 selectsStart
//                                                 dateFormat="yyyy-MM-dd"
//                                             />
//                                             )}
//                                         />
//                                     </div>
//                                     {errors.startDate && <span className="text-white">{errors.startDate.message}</span>}                
//                                     <button disabled={isSubmitting || isLoading} className="lab-btn" type="submit">
//                                         Filter now <i className="icofont-search-2"></i> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                     <div className="member-wrapper">
//                         {isLoading ? (
//                             <SkeletonLoader loading={isLoading} />
//                         ) : data.length === 0 ? (
//                             <div className="text-center">
//                                 <h2>{'No socre found!'}</h2>
//                                 <p> {'Subscribe to get more fascinating scores'}</p>
//                             </div>
//                         ) : (
//                             <Table striped bordered hover>
//                                 <thead>
//                                     <tr>
//                                         <th>SN</th>
//                                         <th>Name</th>
//                                         <th>Telco</th>
//                                         <th>Amount</th>
//                                         <th>Validity Days</th>
//                                         <th>Aggregator Product Code</th>
//                                         <th>Aggregator Credential Password</th>
//                                         <th>Status</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {data.map((item, index) => 
//                                         <tr key={index}>
//                                             <td>1</td>
//                                             <td>{item.name}</td>
//                                             <td>{item.telco}</td>
//                                             <td>{item.amount}</td>
//                                             <td>{item.validity_days}</td>
//                                             <td>{item.aggregator_product_code}</td>
//                                             <td>{item.aggregator_credential_password}</td>
//                                             <td>{item.status}</td>
//                                         </tr>
//                                     )}
//                                 </tbody>
//                             </Table>
//                         )}
//                     </div> */}
//                 </div>
//             </section>
//             <Footer />
//         </div>
//     );
// }

// export default GetScoresPage;



import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import request from '../utils/Helper/Request';
import { useNavigate } from 'react-router-dom';
import Header from '../component/layout/header';
import AuthHelper from '../utils/Helper/AuthHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const GetScoresPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // ** Default Values
    const today = new Date();
    const defaultValues = {
        startDate: today
    };

    // ** Nominate Schema
    const getScoresSchema = yup.object().shape({
        startDate: yup.string().required('Excel File is a required field')
    });

    /** Onsubmit */
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({ defaultValues, resolver: yupResolver(getScoresSchema) });
    
    /** Form Date */
    const formDate = (date) => {
        if (date) {
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const day = dateObj.getDate().toString().padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        }
    };

    /** Handle Onsubmit */
    const onSubmit = async (values) => {
        // setIsLoading(true);
        // const formattedDate = formDate(values.startDate);
        // const response = await request(`${process.env.REACT_APP_API_URL}/partner/trivial/export-scores?date=${formattedDate}`,{ method: 'GET' });
        // setData(response.data);
        // setIsLoading(false);

        // Navigate to a new URL in a new browser window
        const newURL = 'https://www.example.com'; // Replace with your desired URL
        window.open(newURL, '_blank');
    };
    return (
        <div>
            <Header />
            <PageHeader title={'Upload Question'} />
            <section className="blog-section padding-tb pb-lg-110">
                <div className="container">
                    <div className="main-blog">
                        <div className="row">
                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="blog-wrapper">
                                    <div className="post-item">
                                        <div className="post-item-inner">
                                            <div className="post-content w-100">
                                                <h3>Date</h3>
                                                <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                                                    <div className="mb-2">
                                                        <Controller
                                                            name="startDate"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <DatePicker className="form-control date"
                                                                    selected={field.value}
                                                                    onChange={field.onChange}
                                                                    selectsStart
                                                                    dateFormat="yyyy-MM-dd"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.startDate && <span className="text-danger">{errors.startDate.message}</span>}
                                                    <div className="form-group">
                                                        <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                                            <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </div>
    );
}

export default GetScoresPage;