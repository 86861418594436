import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import request from '../utils/Helper/Request';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import AuthHelpers from '../utils/Helper/AuthHelpers';

const title = "PLEASE FILL IN THE FORM TO PLAY GAME";
// const subtitle = "Login With Social Media";

const QuestionPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    //** Default Values */
    const defaultValues = {
        phone: ""
    };

    // ** Nominate Schema
    const nominateSchema = yup.object().shape({
        phone: yup.string().required('Phone Number is a required field')
    });

    //** UseForm */
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(nominateSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => {
        setIsLoading(true);
        request(`${process.env.REACT_APP_API_URL}/service/trivial/question`, { 
            method: 'POST', 
            body: values
        })
        .then((response) => {
            setIsLoading(false);
            if(response.data.status === true) {
                reset();
                AuthHelpers.setPhone({phone: values.phone}, false);
                navigate("/answer", {state: response.data.details});
              } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
              }
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };
    return (
        <div>
            <Header />
            <PageHeader title={'Play Game'} />
            <div className="login-section padding-tb">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title text-center">{title}</h3>
                        <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                            <div className="form-group">
                                <label>Phone NUMBER</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Phone number *"
                                    {...register("phone", { required: true })}
                                />
                                {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                            </div>
                            <div className="form-group">
                                <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                    <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
}

export default QuestionPage;