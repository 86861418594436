
import * as yup from 'yup';
import auth from '../utils/Helper/AuthHelpers';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const ExportScorePage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // ** Default Values
    const today = new Date();
    const defaultValues = {
        startDate: today
    };

    // ** Nominate Schema
    const getScoresSchema = yup.object().shape({
        startDate: yup.string().required('Excel File is a required field')
    });

    /** Onsubmit */
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({ defaultValues, resolver: yupResolver(getScoresSchema) });
    
    /** Form Date */
    const formDate = (date) => {
        if (date) {
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const day = dateObj.getDate().toString().padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        }
    };

    /** Handle Onsubmit */
    const onSubmit = async (values) => {
        setIsLoading(true);
        const formattedDate = formDate(values.startDate);
        const token = auth.getToken();
        const newURL = `${process.env.REACT_APP_API_URL}/partner/trivial/export-scores?date=${formattedDate}&Authorization=${token}`;
        window.open(newURL, '_blank');
        setIsLoading(false);
    };
    return (
        <div>
            <Header />
            <PageHeader title={'Pricing Plan'} />
            <section className="blog-section padding-tb pb-lg-110">
                <div className="container">
                    <div className="main-blog">
                        <div className="row">
                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="blog-wrapper">
                                    <div className="post-item">
                                        <div className="post-item-inner">
                                            <div className="post-content w-100">
                                                <h3>Date</h3>
                                                <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                                                    <div className="mb-2">
                                                        <Controller
                                                            name="startDate"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <DatePicker className="form-control date"
                                                                    selected={field.value}
                                                                    onChange={field.onChange}
                                                                    selectsStart
                                                                    dateFormat="yyyy-MM-dd"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.startDate && <span className="text-danger">{errors.startDate.message}</span>}
                                                    <div className="form-group">
                                                        <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                                            <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </div>
    );
}

export default ExportScorePage;