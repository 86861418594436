import React, { useState } from 'react';
import { ClipLoader } from "react-spinners";

const SkeletonLoader = (props) => {
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};
  return (
    <ClipLoader
      color="#ffffff"
      loading={props.loading}
      cssOverride={override}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default SkeletonLoader;