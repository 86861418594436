// import React, { Component } from 'react';
// import Header from '../component/layout/header';
// import PageHeader from '../component/layout/pageheader';
// import FooterSection from '../component/layout/footer';

// // const subTitle= "Download App Our Turulav";
// const Title = "ABOUT US";
// const desc1 = "Forty and Fabulous is a community of empowered individuals, embracing the beauty of life beyond 40. It's a journey of self-discovery, growth, and empowerment.";
// const desc2 = "We believe that age is just a number, and life can be lived to the fullest at any stage. Our events and activities are designed to uplift, inspire, and connect like-minded people, creating lifelong friendships and unforgettable experiences.";
// const desc3 = "Whether you're looking for personal growth, health and wellness, or simply a good time, Forty and Fabulous is your go-to destination. Come join us and discover the magic of being fabulous at any age.";

// class AboutPage extends Component {
//     render() {
//         return (
//             <div>
//                 <Header />
//                 <PageHeader title={'About'} />
//                 <section className="group-page-section padding-tb">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-6">
//                                 {/* <h4>{subTitle}</h4> */}
//                                 <h2>{Title} </h2>
//                                 <p>{desc1}</p>
//                                 <p>{desc2}</p>
//                                 <p>{desc3}</p>
//                             </div>
//                             <div className="col-lg-6">
//                                 <div className="banner-thumb">
//                                     <img src="assets/images/banner/01.png" alt="img" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <FooterSection />
//             </div>
//         );
//     }
// }

// export default AboutPage;


import React, { Component } from 'react';
import Header from '../component/layout/header';
import FooterSection from '../component/layout/footer';
import AboutSection from '../component/section/about';

class AboutPages extends Component {
    render() {
        return (
            <div>
                <Header />
                <AboutSection />
                <FooterSection />
            </div>
        );
    }
}

export default AboutPages;