import { createContext, useState, useEffect } from 'react';
import AuthHelpers from '../Helper/AuthHelpers';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Get the isLoggedIn state from local storage on initial render
    return AuthHelpers?.getToken() !== null;
  });

  useEffect(() => {
    // Save the isLoggedIn state to local storage whenever it changes
    setIsLoggedIn(AuthHelpers?.getToken() !== null);
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
