import AuthHelpers from '../../utils/Helper/AuthHelpers';
  
/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function hasAccess() {
  return AuthHelpers?.getToken() !== null;
};