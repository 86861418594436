import React, { useState, useEffect } from 'react';
import request from '../utils/Helper/Request';
import Header from '../component/layout/header';
import Footer from '../component/layout/footer';
import PageHeader from '../component/layout/pageheader';
import SkeletonLoader from '../component/items/skeletonLoader';
import Table from 'react-bootstrap/Table';

const GetVotingPage = () => {
    let SN = 1;
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    /** Handle Fetch Data */
    const fetchData = async () => {
        setIsLoading(true);
        const response = await request(`${process.env.REACT_APP_API_URL}/partner/contestants/get-votes`, { method: 'GET' });
        setProducts(response);
        setIsLoading(false);
    };
  
    /** Handle Fetch useEffect */
    useEffect(() => {
        fetchData();
    }, []);
    
    return (
        <div>
            <Header />
            <PageHeader title={'Get Voting'} />
            <section className="member-page-section">
                <div className="container">
                    <div className="member-wrapper">  
                        {/* <PaginaTion /> */}
                        {isLoading ? (
                            <SkeletonLoader loading={isLoading} />
                        ) : products.length === 0 ? (
                            <div className="text-center">
                                <h2>{'No voting found!'}</h2>
                                <p> {'Subscribe to get more fascinating votes'}</p>
                            </div>
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>SN</th>
                                        <th>Code</th>
                                        <th>Handle</th>
                                        <th>Votes</th>
                                        <th>Social Media</th>
                                        <th>Phone</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((item, index) => 
                                        <tr key={index}>
                                            <td>{SN++}</td>
                                            <td>{item.code}</td>
                                            <td>{item.handle}</td>
                                            <td>{item.votes}</td>
                                            <td>{item.social_media}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.remarks}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default GetVotingPage;