import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import NominateForm from '../component/section/nominateform';

const title = "Nomination";
// const subtitle = "Register With Social Media";

const NominatePage = () => {
    const [display, setDisplay] = useState(false);
    return (
        <div>
            <Header />
            <PageHeader title={'Nominate'}/>
            <div className="login-section padding-tb">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <NominateForm 
                            display={display} 
                            setDisplay={setDisplay}
                        />
                    </div>
                </div>
                <div className="text-center mt-3">
                    <span className="d-block cate pt-10">Do you want to Vote? <Link to="/vote">Click Here</Link></span>
                </div>
            </div>
            <FooterSection />
        </div>
    );
}

export default NominatePage;