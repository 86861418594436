import React, { Component } from 'react';
// import SectionHeader from './sectionheader';

// const subtitle= <h4>About Our Turulav</h4>;
// const title= <h2>It All Starts With A Date</h2>;
// const alignment= 'section-header';

// let AboutContentList = [
//     {
//         imgUrl: 'assets/images/about/01.png',
//         imgAlt: 'About Thumb',
//         countNumber: '29,991',
//         countTitle: "Members in Total",
//     },
//     {
//         imgUrl: 'assets/images/about/02.png',
//         imgAlt: 'About Thumb',
//         countNumber: '29,000',
//         countTitle: "Members Online",
//     },
//     {
//         imgUrl: 'assets/images/about/03.png',
//         imgAlt: 'About Thumb',
//         countNumber: '14,000',
//         countTitle: "Men Online",
//     },
//     {
//         imgUrl: 'assets/images/about/04.png',
//         imgAlt: 'About Thumb',
//         countNumber: '15,000',
//         countTitle: "Women Online",
//     },
// ]

// const bannertitle = "INTRODUCING 🎯 FORTY AND FABULOUS";
// const bannerdesc = <p>Join the fun and put your knowledge to the test! <strong>Subscribe to play a Game, </strong> answer questions and earn points</p>

class AboutSection extends Component {
    state = {  } 
    render() { 
        return (
            <>
            <section className="banner-section">
                <div className="container"> <br />
                    <div className="section-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h2>ABOUT US</h2>
                                <p>Forty and Fabulous is a community of empowered individuals, embracing the beauty of life beyond 40. It's a journey of self-discovery, growth, and empowerment.</p>
                                <p>We believe that age is just a number, and life can be lived to the fullest at any stage. Our events and activities are designed to uplift, inspire, and connect like-minded people, creating lifelong friendships and unforgettable experiences.</p>
                                <p>Whether you're looking for personal growth, health and wellness, or simply a good time, Forty and Fabulous is your go-to destination. Come join us and discover the magic of being fabulous at any age.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumb">
                                    <img src="assets/images/banner/01.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="about-section padding-tb bg-img">
                <div className="container">
                    <SectionHeader 
                        title={ title } 
                        subtitle={ subtitle }
                        alignment={alignment}
                    />
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-4">
                            {AboutContentList.map((val, i) =>(
                                <div className="col-xl-3 col-lg-4 col-sm-6 col-12" key={i}>
                                    <div className="lab-item about-item">
                                        <div className="lab-inner text-center">
                                            <div className="lab-thumb">
                                                <img 
                                                    src={`${val.imgUrl}`} 
                                                    alt={`${val.imgAlt}`} 
                                                />
                                            </div>
                                            <div className="lab-content">
                                                <h2 className="counter">{val.countNumber}</h2>
                                                <p>{val.countTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section> */}
            </>
        );
    }
}
 
export default AboutSection;