import {BrowserRouter} from "react-router-dom";
import ScrollToTop from './component/layout/scralltop';
import Router from './routes';
import { AuthProvider } from './utils/Hooks/useAuth';

function App() {
	return (
		<AuthProvider>
			{/* <AuthInit> */}
				<BrowserRouter>
					<ScrollToTop />
					<Router />
				</BrowserRouter>
			{/* </AuthInit> */}
		</AuthProvider>
	);
}

export default App;
