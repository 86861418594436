import * as yup from 'yup';
import { toast } from 'react-toastify';
import request from '../utils/Helper/Request';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../component/layout/header';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import AuthHelpers from '../utils/Helper/AuthHelpers';
import SkeletonLoader from '../component/items/skeletonLoader';

const AnswerPage = () => {
    const auth = AuthHelpers.getPhone();
    const [data, setData] = useState({});
    const [isSpinning, setIsSpinning] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();  
    const navigate = useNavigate();
    
    useEffect(() => {
        if (location && location.state !== null) {
            setData(location.state);
            setIsSpinning(false);
        } else {
            setData({});
            setIsSpinning(false);

        }

    }, [location.state]);
    
    const fetchMoreQuestion = () => {
        setIsSpinning(true);
        request(`${process.env.REACT_APP_API_URL}/service/trivial/question`, { 
            method: 'POST', 
            body: {
                phone: auth.phone
            }
        })
        .then(async(response) => {
            setIsSpinning(false);
            if(response.data.status === true) {
                setData(response.data.details);
            } else {
                setData({});
                navigate("/answer", { state: null });
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };

    //** Default Values */
    const defaultValues = {
        option_value: ""
    };

    // ** Nominate Schema
    const nominateSchema = yup.object().shape({
        option_value: yup.string().required('Option is a required field')
    });

    //** UseForm */
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(nominateSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => { 
        setIsLoading(true);
        request(`${process.env.REACT_APP_API_URL}/service/trivial/answer`, { 
            method: 'POST', 
            body: {
                phone: auth.phone,
                text: values.option_value
            }
        })
        .then((response) => {
            setIsLoading(false);
            if(response.data.status === true) {
                toast.success(response.data.details.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setTimeout(function() {
                    fetchMoreQuestion();
                }, 2000);
            } else {
                setData({});
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };

    return (
        <div>
            <Header />
            <PageHeader title={'Answer'} />
            <section className="blog-section padding-tb">
                <div className="container">
                    <div className="main-blog">
                        <div className="row">
                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="blog-wrapper">
                                    <div className="post-item">
                                        <div className="post-item-inner">
                                            <div className="post-content">
                                                <h3>Question</h3>
                                                {isSpinning ? (
                                                    <SkeletonLoader loading={isSpinning} />
                                                ) : Object.keys(data).length === 0 ? (
                                                    <div className="text-center">
                                                        <h2>{'No question found!'}</h2>
                                                        <p> {'Subscribe to get more fascinating questions'}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{data.question}</p>
                                                        <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                                                            <Controller
                                                                name="option_value"
                                                                control={control}
                                                                render={({ field: { onChange, value } }) => (
                                                                <div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" value={data.option_1_key} id="option_value_1" checked={value === data.option_1_key} onChange={onChange}/>
                                                                        <label className="form-check-label" htmlFor="option_value_1">
                                                                            {data.option_1_value}
                                                                        </label>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" value={data.option_2_key} id="option_value_2" checked={value === data.option_2_key} onChange={onChange}/>
                                                                        <label className="form-check-label" htmlFor="option_value_2">
                                                                            {data.option_2_value}
                                                                        </label>
                                                                    </div>
                                                                    
                                                                </div>
                                                                )}
                                                            />
                                                            {errors.option_value && <span className="text-danger">{errors.option_value.message}</span>}
                                                            <div className="form-group">
                                                                <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                                                    <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </div>
    );
}

export default AnswerPage;