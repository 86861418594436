
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import auth from '../utils/Helper/AuthHelpers';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import "react-datepicker/dist/react-datepicker.css";

const ExportVotingPage = () => {
    const [isLoading, setIsLoading] = useState(false);

    /** Onsubmit */
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = useForm();

    /** Handle Onsubmit */
    const onSubmit = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const newURL = `${process.env.REACT_APP_API_URL}/partner/contestants/export-votes?Authorization=${token}`;
        window.open(newURL, '_blank');
        setIsLoading(false);
    };
    return (
        <div>
            <Header />
            <PageHeader title={'Export Voting'} />
            <section className="blog-section padding-tb pb-lg-110">
                <div className="container">
                    <div className="main-blog">
                        <div className="row">
                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="blog-wrapper">
                                    <div className="post-item">
                                        <div className="post-item-inner">
                                            <div className="post-content w-100">
                                                <h3>Click the button to Export</h3>
                                                <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                                                    <div className="form-group">
                                                        <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                                            <span>Export Nominees</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </div>
    );
}

export default ExportVotingPage;