import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import request from '../utils/Helper/Request';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import { yupResolver } from '@hookform/resolvers/yup';
// import AuthHelper from '../utils/Helper/AuthHelpers';

const title = "Vote";
// const subtitle = "Register With Social Media";

const VotingPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    
    //** Default Values */
    const defaultValues = {
        code: "",
        phone: ""
    };

    // ** Nominate Schema
    const nominateSchema = yup.object().shape({
        code: yup.string().required('Code is a required field'),
        phone: yup.string().required('Phone is a required field')
    });

    //** UseForm */
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(nominateSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => {
        setIsLoading(true);
        request(`${process.env.REACT_APP_API_URL}/service/fabulous/vote`, { 
            method: 'POST', 
            body: {
                code: values.code,
                phone: values.phone
            }
        })
        .then((response) => {
            reset();
            setIsLoading(false);
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };

    return (
        <div>
            <Header />
            <PageHeader title={'Vote'}/>
            <div className="login-section padding-tb">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        
                        <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                            <div className="form-group">
                                <label>Code</label>
                                <input
                                    type="text"
                                    name="code"
                                    placeholder="Your Code *"
                                    {...register("code", { required: true })}
                                />
                                {errors.code && <span className="text-danger">{errors.code.message}</span>}
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input 
                                    type="text"
                                    name="phone"
                                    placeholder="Your Name *"
                                    {...register("phone", { required: true })}
                                />
                                {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                            </div>
                            <div className="form-group">
                                <button disabled={isSubmitting || isLoading} className="d-block lab-btn">
                                    <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
}

export default VotingPage;