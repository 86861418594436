import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import request from '../../utils/Helper/Request';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';

const NominateForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const codeFromQuery = queryParams.get('code');
        if (codeFromQuery) {
          setValue('code', codeFromQuery);
        }
    }, [location]);
    
    //** Default Values */
    const defaultValues = {
        code: "",
        social_media_type: "",
        social_media_handle: "",
        fullname: "",
        marital_status: "",
        email: "",
        address: "",
    };

    // ** Nominate Schema
    const nominateSchema = yup.object().shape({
        code: yup.string().required('Code is a required field'),
        social_media_type: yup.string().required('Social media type is a required field'),
        social_media_handle: yup.string().required('Social media handle is a required field'),
        fullname: yup.string().required('Full Name is a required field'),
        marital_status: yup.string().required('Marital status is a required field'),
        email: yup.string().required('Email is a required field'),
        address: yup.string().required('Address is a required field'),
    });

    //** UseForm */
    const {
        reset,
        setValue,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(nominateSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => {
        setIsLoading(true);
        request(`${process.env.REACT_APP_API_URL}/service/fabulous/nominate`, { 
            method: 'POST', 
            body: {
                code: values.code,
                social_media_type: values.social_media_type,
                social_media_handle: values.social_media_handle,
                name: values.fullname,
                marital_status: values.marital_status,
                email: values.email,
                address: values.address
            }
        })
        .then((response) => {
            reset();
            navigate('/success');
            setIsLoading(false);
            props.setDisplay(false);
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="account-form">
            <div className="form-group">
                <label>Code (Use code sent to your phone)</label>
                <input
                    type="text"
                    name="code"
                    placeholder="Your Code *"
                    {...register("code", { required: true })}
                />
                {errors.code && <span className="text-danger">{errors.code.message}</span>}
            </div>
            <div className="form-group">
                <label>Social Media Type</label>
                <select
                    name="email"
                    className="w-100"
                    {...register("social_media_type", { required: true })}
                >
                    <option value="">---Select---</option>
                    <option value="INSTAGRAM">INSTAGRAM</option>
                    <option value="FACEBOOK">FACEBOOK</option>
                    <option value="TWITTER">TWITTER</option>
                    <option value="THREAD">THREAD</option>
                </select>
                {errors.social_media_type && <span className="text-danger">{errors.social_media_type.message}</span>}
            </div>
            <div className="form-group">
                <label>Social Media Handle</label>
                <input 
                    type="text"
                    name="social_media_handle"
                    placeholder="Social Media Handle *"
                    {...register("social_media_handle", { required: true })}
                />
                {errors.social_media_handle && <span className="text-danger">{errors.social_media_handle.message}</span>}
            </div>
            <div className="form-group">
                <label>Full Name</label>
                <input 
                    type="text"
                    name="fullname"
                    placeholder="Your Full Name *"
                    {...register("fullname", { required: true })}
                />
                {errors.fullname && <span className="text-danger">{errors.fullname.message}</span>}
            </div>
            <div className="form-group">
                <label>Marital Status</label>
                <select
                    name="email"
                    className="w-100"
                    {...register("marital_status", { required: true })}
                >
                    <option value="">---Select---</option>
                    <option value={"Single"}>Single</option>
                    <option value={"Married"}>Married</option>
                </select>
                {errors.marital_status && <span className="text-danger">{errors.marital_status.message}</span>}
            </div>
            <div className="form-group">
                <label>Email</label>
                <input 
                    type="text"
                    name="email"
                    placeholder="Your Email *"
                    {...register("email", { required: true })}
                />
                {errors.email && <span className="text-danger">{errors.email.message}</span>}
            </div>
            <div className="form-group">
                <label>Address</label>
                <input 
                    type="text"
                    name="address"
                    placeholder="Your Address *"
                    {...register("address", { required: true })}
                />
                {errors.address && <span className="text-danger">{errors.address.message}</span>}
            </div>
            <div className="form-group">
                <button disabled={isSubmitting || isLoading} className="d-block lab-btn">
                    <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                </button>
            </div>
        </form>
    );
}

export default NominateForm;








// import React, { useState } from 'react';
// import { Button, Form, ProgressBar } from 'react-bootstrap';
// import * as yup from 'yup';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { toast } from 'react-toastify';
// import request from '../../utils/Helper/Request';

// // Define validation schema for the first step
// const step1Schema = yup.object().shape({
//   code: yup.string().required('Code is a required field'),
//   social_media_type: yup.string().required('Social media type is a required field'),
//   social_media_handle: yup.string().required('Social media handle is a required field'),
//   name: yup.string().required('Name is a required field'),
//   description: yup.string().required('Description is a required field'),
// });

// // Define validation schema for the second step (file upload)
// const step2Schema = yup.object().shape({
//   file: yup.mixed().required('File is a required field'),
// });

// const NominateForm = (props) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentStep, setCurrentStep] = useState(1);

//   // Define form data for each step
//   const [formDataStep1, setFormDataStep1] = useState({
//     code: '',
//     social_media_type: '',
//     social_media_handle: '',
//     name: '',
//     description: '',
//   });

//   const [formDataStep2, setFormDataStep2] = useState({
//     file: null,
//   });

//   // Use useForm for each step with the respective schema
//   const { register: registerStep1, handleSubmit: handleSubmitStep1, formState: { errors: errorsStep1 } } = useForm({
//     defaultValues: formDataStep1,
//     resolver: yupResolver(step1Schema),
//   });

//   const { register: registerStep2, handleSubmit: handleSubmitStep2, formState: { errors: errorsStep2 } } = useForm({
//     defaultValues: formDataStep2,
//     resolver: yupResolver(step2Schema),
//   });

//   const handleNext = () => {
//     setCurrentStep(currentStep + 1);
//   };

//   const handlePrev = () => {
//     setCurrentStep(currentStep - 1);
//   };

//   const onSubmitStep1 = (values) => {
//     setFormDataStep1(values);
//     handleNext();
//   };

//   const onSubmitStep2 = (values) => {
//     setFormDataStep2(values);

//     // Combine formDataStep1 and formDataStep2
//     const combinedFormData = {
//       ...formDataStep1,
//       ...values,
//     };
//     setIsLoading(true);
//     request(`${process.env.REACT_APP_API_URL}/service/fabulous/nominate`, {
//       method: 'POST',
//       body: combinedFormData,
//     })
//       .then((response) => {
//         setIsLoading(false);
//         props.setDisplay(false);
//         toast.success(response.message, {
//           position: toast.POSITION.TOP_RIGHT,
//         });
//       })
//       .catch((error) => {
//         if (error.response.status > 200) {
//           setIsLoading(false);
//           toast.error(error.response.payload.message, {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         }
//       });
//   };

//   return (
//     <div>
//       {currentStep === 1 && (
//         <Form onSubmit={handleSubmitStep1(onSubmitStep1)} className="account-form">
//             {/* Step 1 form fields */}
//             <div className="form-group">
//                 <label>Code (Use code sent to your phone)</label>
//                 <input
//                     type="text"
//                     name="code"
//                     placeholder="Your Code *"
//                     {...registerStep1("code")}
//                 />
//                 {errorsStep1.code && (
//                     <span className="text-danger">{errorsStep1.code.message}</span>
//                 )}
//                 </div>
//                 <div className="form-group">
//                 <label>Social Media Type</label>
//                 <select
//                     name="social_media_type"
//                     className="w-100"
//                     {...registerStep1("social_media_type")}
//                 >
//                     <option value="">---Select---</option>
//                     <option value="INSTAGRAM">INSTAGRAM</option>
//                     <option value="FACEBOOK">FACEBOOK</option>
//                     <option value="TWITTER">TWITTER</option>
//                     <option value="THREAD">THREAD</option>
//                 </select>
//                 {errorsStep1.social_media_type && (
//                     <span className="text-danger">
//                     {errorsStep1.social_media_type.message}
//                     </span>
//                 )}
//                 </div>
//                 <div className="form-group">
//                 <label>Social Media Handle</label>
//                 <input
//                     type="text"
//                     name="social_media_handle"
//                     placeholder="Social Media Handle *"
//                     {...registerStep1("social_media_handle")}
//                 />
//                 {errorsStep1.social_media_handle && (
//                     <span className="text-danger">
//                     {errorsStep1.social_media_handle.message}
//                     </span>
//                 )}
//                 </div>
//                 <div className="form-group">
//                 <label>Name</label>
//                 <input
//                     type="text"
//                     name="name"
//                     placeholder="Your Name *"
//                     {...registerStep1("name")}
//                 />
//                 {errorsStep1.name && (
//                     <span className="text-danger">{errorsStep1.name.message}</span>
//                 )}
//                 </div>
//                 <div className="form-group">
//                 <label>Description</label>
//                 <textarea
//                     rows="8"
//                     name="description"
//                     className="w-100"
//                     placeholder="Your Description"
//                     {...registerStep1("description")}
//                 ></textarea>
//                 {errorsStep1.description && (
//                     <span className="text-danger">
//                     {errorsStep1.description.message}
//                     </span>
//                 )}
//             </div>
//         </Form>
//       )}

//       {currentStep === 2 && (
//         <Form onSubmit={handleSubmitStep2(onSubmitStep2)} className="account-form">
//           {/* Step 2 form fields */}
//           <Form.Group>
//             <Form.Label>File Upload</Form.Label>
//             <Form.File
//               name="file"
//               {...registerStep2('file')}
//               isInvalid={!!errorsStep2.file}
//             />
//             {errorsStep2.file && (
//               <Form.Text className="text-danger">{errorsStep2.file.message}</Form.Text>
//             )}
//           </Form.Group>
//         </Form>
//       )}

//       <div className="form-buttons">
//         {currentStep === 2 ? (
//           <>
//             <Button onClick={handlePrev}>Previous</Button>
//             <Button type="submit" onClick={handleSubmitStep2(onSubmitStep2)} disabled={isLoading}>
//               Submit
//             </Button>
//           </>
//         ) : (
//           <Button type="submit" onClick={handleSubmitStep1(onSubmitStep1)} disabled={isLoading}>
//             Next
//           </Button>
//         )}
//       </div>

//       {/* <ProgressBar animated now={(currentStep / 2) * 100} /> */}
//     </div>
//   );
// };

// export default NominateForm;
