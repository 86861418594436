import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import request from '../utils/Helper/Request';
import { useNavigate } from 'react-router-dom';
import Header from '../component/layout/header';
import React, { useState, useContext } from 'react';
import AuthHelper from '../utils/Helper/AuthHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import { AuthContext } from '../utils/Hooks/useAuth';

const title = "Login";
const subtitle = "Login With Social Media";

const LoginPage = () => {
    const { setIsLoggedIn } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    //** Default Values */
    const defaultValues = {
        email: "",
        password: ""
    };

    // ** Nominate Schema
    const nominateSchema = yup.object().shape({
        email: yup.string().required('Email is a required field'),
        password: yup.string().required('Password is a required field')
    });

    //** UseForm */
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(nominateSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => {
        setIsLoading(true);
        request(`${process.env.REACT_APP_API_URL}/auth/login`, { 
            method: 'POST', 
            body: values
        })
        .then((response) => {
            reset();
            setIsLoggedIn(true);
            setIsLoading(false);
            AuthHelper.setToken(response.data.access_token);
            AuthHelper.setUserInfo(response.data.user);
            navigate("/products");
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };
    return (
        <div>
            <Header />
            <PageHeader title={'Log In'} />
            <div className="login-section padding-tb">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title text-center">{title}</h3>
                        <p className="text-center">{subtitle}</p>
                        <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="User Name *"
                                    {...register("email", { required: true })}
                                />
                                {errors.email && <span className="text-danger">{errors.email.message}</span>}
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password *"
                                    {...register("password", { required: true })}
                                />
                                {errors.password && <span className="text-danger">{errors.password.message}</span>}
                            </div>
                            <div className="form-group">
                                <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                    <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
}

export default LoginPage;