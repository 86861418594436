import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import request from '../../utils/Helper/Request';
import AuthHelpers from '../../utils/Helper/AuthHelpers';
import { yupResolver } from '@hookform/resolvers/yup';

const GenerateCodeForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
    //** Default Values */
    const defaultValues = {
        phone: ""
    };

    // ** Nominate Schema
    const generateSchema = yup.object().shape({
        phone: yup.string().required('Phone number is a required field')
    });

    //** UseForm */
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(generateSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => {
        setIsLoading(true);
        request(`${process.env.REACT_APP_API_URL}/service/fabulous/generate-nomination-code`, { 
            method: 'POST', 
            body: {
                phone: values.phone
            }
        })
        .then((response) => { 
            if (response.result.status === false) {
                setIsLoading(false);
                toast.error(response.result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                reset();
                setIsLoading(false);
                props.setDisplay(true);
                navigate("/nominate");
                AuthHelpers.setPhone({phone: values.phone});
                toast.success(response.result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="account-form">
            <div className="form-group">
                <label>Phone Nmuber</label>
                <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone Number *"
                    {...register("phone", { required: true })}
                />
                {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
            </div>
            <div className="form-group">
                <button disabled={isSubmitting || isLoading} className="d-block lab-btn">
                    <span>Generate Code</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                </button>
            </div>
        </form>
    )
}

export default GenerateCodeForm;