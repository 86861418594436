import React, { Component } from 'react';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import { Link } from 'react-router-dom';

const ExportScorePage = () => {
    

    return (
        <div>
            <Header />
            <PageHeader title={'Get Started'} />
            <div className="contact-section">
                <div className="contact-top padding-tb aside-bg padding-b">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <Link to="/nominatecode">
                                    <article className="contact-form-wrapper">
                                        <div className="contact-form text-center">
                                            <img src="assets/images/contact/01.png" alt="" />
                                            <h4>{"Nomination"}</h4>
                                            <p className="mb-5 mw-100">{"Select nomination to get started"}</p>
                                        </div>
                                    </article>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-3">
                                <Link to="/question">
                                    <article className="contact-form-wrapper">
                                        <div className="contact-form text-center">
                                            <img src="assets/images/contact/01.png" alt="" />
                                            <h4>{"Play Trivia"}</h4>
                                            <p className="mb-5 mw-100">{"Select play trivia to get started"}</p>
                                        </div>
                                    </article>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
}

export default ExportScorePage;