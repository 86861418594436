import 'whatwg-fetch';
import auth from './AuthHelpers';
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json ? response.json() : response;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return parseJSON(response).then(responseFormatted => {
    const error = new Error(response.statusText);
    error.response = response;
    error.response.payload = responseFormatted;
    throw error;
  });
}

/**
 * Format query params
 *
 * @param params
 * @returns {string}
 */
function formatQueryParams(params) {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options = {}) {
  // Extract the body from options
  const { body, ...restOptions } = options; 

  // Set headers
  const headers = {
    'Content-Type': 'application/json',
    ...restOptions.headers,
  };

  const token = auth.getToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  let fetchOptions = { ...restOptions, headers };

  if (body instanceof FormData) {
    // If body is FormData, don't stringify it and remove Content-Type header
    fetchOptions = { ...fetchOptions, body };
    delete fetchOptions.headers['Content-Type'];
    
  } else if (body && typeof body === 'object') {
    // Stringify body object
    fetchOptions.body = JSON.stringify(body);
  }

  if (fetchOptions.params) {
    const params = formatQueryParams(fetchOptions.params);
    url = `${url}?${params}`;
  }

  return fetch(url, fetchOptions).then(checkStatus).then(parseJSON);
}
