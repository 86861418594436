import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const title = "You’re Just 3 Steps Away From Playing A Great Game";
const subtitle = "How Does It Work?";

let WorkListContent = [
    {
        linkUrl: '/subscribe',
        imgUrl: 'assets/images/work/01.png',
        imgAlt: 'work thumb',
        step: 'step',
        stepCount: '01',
        title: 'Subscribe to Forty & Fabulous',
        desc: 'Experience the full benefits of Forty & Fabulous, Elevate your experience and be a part of the Forty & Fab community.'
    },
    {
        linkUrl: '/question',
        imgUrl: 'assets/images/work/02.png',
        imgAlt: 'work thumb',
        step: 'step',
        stepCount: '02',
        title: 'Play the Trivial Game',
        desc: 'Engage in mind-bending fun with Trivial Game, with trivial questions, test knowledge, and have fun in the Trivial Game.',
    },
    {
        linkUrl: '/nominate',
        imgUrl: 'assets/images/work/03.png',
        imgAlt: 'work thumb',
        step: 'step',
        stepCount: '03',
        title: 'Become the Champion',
        desc: 'Believe in yourself, train hard, overcome challenges, stay focused, and rise as a true champion in every endeavor.',
    },
]

class WorkSection extends Component {
    state = {  } 
    render() { 
        return (
            <section className="work-section padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h4 className="theme-color">{subtitle}</h4>
                        <h2>{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-5">
                            {WorkListContent.map((val, i) => (
                                <div className="col-lg-4 col-sm-6 col-12 px-4" key={i}>
                                    <Link to={val.linkUrl}>
                                    <div className="lab-item work-item">
                                        <div className="lab-inner text-center">
                                            <div className="lab-thumb">
                                                <div className="thumb-inner">
                                                    <img 
                                                        src={`${val.imgUrl}`} 
                                                        alt={`${val.imgAlt}`} 
                                                    />
                                                    <div className="step">
                                                        <span>{val.step}</span>
                                                        <p>{val.stepCount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lab-content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WorkSection;