import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { hasAccess } from '../../utils/Helper/AssetHelpers';

// const contactnumber = "+800-123-4567 6587";
// const location = "Beverley, New York 224 USA";

class Header extends Component {

    menuTrigger() {
        document.querySelector('.menu').classList.toggle('active')
        document.querySelector('.header-bar').classList.toggle('active')
    }
    menuTriggerTwo() {
        document.querySelector('.header-top').classList.toggle('open')
        // document.querySelector('.header-bar').classList.toggle('active')
    }
    // menuTriggerThree() {
    //     document.querySelector('.menu-item-has-children').classList.toggle('open')
    //     document.querySelector('.header-bar').classList.remove('active')
    // }
    
    render() {
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header-section').classList.add(['header-fixed'], ['fadeInUp'])
            }else{
                document.querySelector('.header-section').classList.remove(['header-fixed'], ['fadeInUp'])
            }
        });

        // var elements = document.querySelectorAll('.menu-item-has-children > a');
        // for(var i in elements) {
        //     if(elements.hasOwnProperty(i)) {
        //         elements[i].onclick = function() {
        //             this.parentElement.querySelector('.submenu').classList.toggle("active");
        //             this.classList.toggle("open");
        //         }
        //     }
        // }

        return (
            <header className="header-section">
                {/* <div className="header-top">
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="left">
                                <li>
                                    <i className="icofont-ui-call"></i> <span>{contactnumber}</span>
                                </li>
                                <li>
                                    <i className="icofont-location-pin"></i> {location}
                                </li>
                            </ul>
                            <ul className="social-icons d-flex align-items-center">
                                <li>
                                    <p>
                                        Find us on :
                                    </p>
                                </li>
                                <li>
                                    <a href="#" className="fb"><i className="icofont-facebook-messenger"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="twitter"><i className="icofont-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="vimeo"><i className="icofont-vimeo"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="skype"><i className="icofont-skype"></i></a>
                                </li>
                                <li>
                                    <a href="#" className="rss"><i className="icofont-rss-feed"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo fs-4">
                                <Link to="/">
                                    {/* <img src="assets/images/logo/logo.png" alt="logo" /> */}
                                    Forty & Fabulous
                                </Link>
                            </div>
                            <div className="menu-area">
                                <ul className="menu">
                                    <li>
                                        <NavLink to="/">Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about">About</NavLink>
                                    </li>
                                    {hasAccess() && (
                                    <>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Trivial</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/upload-question">Upload questions</NavLink></li>
                                            <li><NavLink to="/get-scores">Get Scores</NavLink></li>
                                            <li><NavLink to="/export-scores">Export Scores</NavLink></li>

                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Products</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/products">List Products</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Forty</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/get-nominees">Get Nominees</NavLink></li>
                                            <li><NavLink to="/export-nominees">Export Nominees</NavLink></li>
                                            <li><NavLink to="/upload-contestants">Upload Contestants</NavLink></li>
                                            <li><NavLink to="/get-voting">Get Voting</NavLink></li>
                                            <li><NavLink to="/export-voting">Export Voting</NavLink></li>
                                            <li><NavLink to="/export-audience-participation">Export Audience Participation </NavLink></li>
                                        </ul>
                                    </li>
                                    </>
                                    )}
                                    <li><NavLink to="/contact">Contact</NavLink></li>
                                    <li className="d-lg-none"><NavLink to="/subscribe">Subscribe</NavLink></li>
                                    <li className="d-lg-none"><NavLink to="/get-started">Get Started</NavLink></li>
                                </ul>
                                <NavLink to="/subscribe" className="login"><i className="icofont-user"></i> <span>SUBSCRIBE</span> </NavLink>
                                <NavLink to="/get-started" className="signup"><i className="icofont-users"></i> <span>GET STARTED</span> </NavLink>

                                <div className="header-bar d-lg-none" onClick={this.menuTrigger}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                {/* <div className="ellepsis-bar d-lg-none" onClick={this.menuTriggerTwo}>
                                    <i className="icofont-info-square"></i>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;