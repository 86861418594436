import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import request from '../utils/Helper/Request';
import { useNavigate } from 'react-router-dom';
import Header from '../component/layout/header';
import AuthHelper from '../utils/Helper/AuthHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';

const UploadQuestionPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    //** Default Values */
    const defaultValues = {
        file: ""
    };

    // ** Nominate Schema
    const uploadSchema = yup.object().shape({
        file: yup.string().required('Excel File is a required field')
    });

    //** UseForm */
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues, resolver: yupResolver(uploadSchema) });

    //** Handle Onsubmit */
    const onSubmit = values => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", values.file[0]);
        request(`${process.env.REACT_APP_API_URL}/partner/trivial/upload-questions`, { 
            method: 'POST', 
            body: formData
        })
        .then((response) => {
            reset();
            setIsLoading(false);
            AuthHelper.setToken(response.data.access_token);
            AuthHelper.setUserInfo(response.data.user);
            navigate("/products");
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
        .catch((error) => {
            if (error.response.status > 200) {
                setIsLoading(false);
                toast.error(error.response.payload.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    };
    return (
        <div>
            <Header />
            <PageHeader title={'Upload Question'} />
            <section className="blog-section padding-tb pb-lg-110">
                <div className="container">
                    <div className="main-blog">
                        <div className="row">
                            <div className="col-lg-8 col-12 mx-auto">
                                <div className="blog-wrapper">
                                    <div className="post-item">
                                        <div className="post-item-inner">
                                            <div className="post-content">
                                                <h3>Upload Excel File</h3>
                                                <form onSubmit={handleSubmit(onSubmit)} className="account-form">
                                                    <div className="form-group">
                                                        <label>File</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            {...register('file')}
                                                        />
                                                        {errors.file && <span className="text-danger">{errors.file.message}</span>}
                                                    </div>
                                                    <div className="form-group">
                                                        <button disabled={isSubmitting || isLoading} className="d-block lab-btn" type="submit">
                                                            <span>Submit Now</span> {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </div>
    );
}

export default UploadQuestionPage;